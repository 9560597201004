import React from 'react';
import Header from './header';
import Main from './main';
import '../assets/scss/style.scss';

const Home = (props) => {
  return (
    <div>
      <Header />
      <Main />
    </div>
  )
}
export default Home;
