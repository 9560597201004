import { useEffect, useState } from "react";

const Header = () => {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleLoading = async () => {
    document.addEventListener("DOMContentLoaded", () => {setLoaded(false)});
  }
  useEffect(() => {
    handleLoading()
    setTimeout(() => {
      setLoading(false)
    }, 300);
  }, [loaded])

  return (
    <header className={loading ? '' : 'loaded'}>
      <div className="logo-body">
        <img className='logo' src='/images/logo.png' alt="Mavi Vatan Logo" />
      </div>
    </header>
  );
}

export default Header;
