import axios from "axios";
import React, { Component } from "react";



class Main extends Component {
    constructor(props){
        super(props);
        this.state = {
            leader_board: [],
            search_key: '',
        }
    }

    componentDidMount() {
        axios.post('https://panel.bilkazanoyna.com/blue-homeland/rank')
        .then(({data}) => {
            this.setState({leader_board: data.rank})
        }).catch(({response}) => {
        })
    }
    
    handleChange = async(e) => {
        await this.setState({
            search_key: e.target.value
        });
        axios.post(`https://panel.bilkazanoyna.com/blue-homeland/check-rank`, {key: this.state.search_key})
        .then(({data}) => {
            if(data.user.length > 0){
                this.setState({
                    leader_board: data.user,
                })
            }
        }).catch(({response}) => {
        })
    }

    render(){
        return (
            <main>
                <div className="container">
                    <div className="leader-board">
                        <ul>
                            <li className={`rank-0`} >
                                <div>
                                    <span className={`w-bg`}></span>
                                </div>
                                <div>
                                    <input type="text" 
                                        onChange={this.handleChange} 
                                        value={this.state.search_key} 
                                        placeholder="Kullanıcı Adınızı Yazın"
                                    />
                                </div>
                            </li>
                            {
                                this.state.leader_board.map((item, index) => {
                                    return (
                                        <li className={`rank-${index + 1}`} key={`tr-${index}`}>
                                            <div>
                                               <span className={`w-bg`}>{item.rank}</span>
                                            </div>
                                            <div>
                                               <span className="username">{item.username}</span>
                                               <span className="w-bg">{item.points}</span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </main>
        )
    }
}

export default Main;